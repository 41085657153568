<template>
  <div class="uk-margin-top">
    <div class="uk-card uk-card-default uk-padding-small profile-wrapper">
      <form @submit.prevent="onSubmit">
        <div
          class="uk-child-width-expand@s uk-grid-small"
          uk-grid
        >
          <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l uk-width-1-2@xl">
            <div class="uk-grid uk-grid-small">
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                Password Baru
              </div>
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl input-wrapper">
                <div class="uk-form-controls password-field uk-width-expand">
                  <input
                    id="password"
                    v-model="password"
                    v-validate="'required|min:8'"
                    name="password"
                    class="uk-input"
                    :type="passwordFieldType"
                    placeholder="Masukkan password..."
                    autocomplete="off"
                    :class="{'uk-form-danger': errors.has('password')}"
                  >
                  <img
                    v-lazy="`${images}/icon/${eyeIcon}`"
                    alt=""
                    class="toggle-eye"
                    @click="passwordVisibility"
                  >
                </div>
                <span
                  v-show="errors.has('password')"
                  class="uk-text-small uk-text-danger"
                >{{ errors.first('password') }}</span>
              </div>
            </div>
            <div class="uk-grid uk-grid-small">
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                Konfirmasi Password Baru
              </div>
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl input-wrapper">
                <div class="uk-form-controls password-field uk-width-expand">
                  <input
                    id="confirm_password"
                    v-model="confirmPassword"
                    v-validate="'required|min:8'"
                    name="confirm_password"
                    class="uk-input"
                    :type="confirmPasswordFieldType"
                    placeholder="Masukkan password..."
                    autocomplete="off"
                    :class="{'uk-form-danger': errors.has('confirm_password')}"
                  >
                  <img
                    v-lazy="`${images}/icon/${eyeIconConfirm}`"
                    alt=""
                    class="toggle-eye"
                    @click="confirmPasswordVisibility"
                  >
                </div>
                <span
                  v-show="errors.has('confirm_password')"
                  class="uk-text-small uk-text-danger"
                >{{ errors.first('confirm_password',) }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="uk-width-1-1 uk-text-right uk-margin-top">
          <button
            class="uk-button uk-button-danger uk-width-expand uk-width-auto@s"
            type="button"
            @click="goToBack"
          >
            <img
              v-lazy="`${images}/icon/close.svg`"
              alt=""
              class="uk-margin-small-right"
            >
            Batal
          </button>
          <button
            class="uk-button uk-button-primary uk-margin-top uk-margin-remove-top-med uk-margin-remove-left@s uk-margin-left-med uk-width-expand uk-width-auto@s"
            type="submit"
          >
            <img
              v-lazy="`${images}/icon/save.svg`"
              alt=""
              class="uk-margin-small-right"
            >
            Simpan
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { PREFIX_IMAGE } from '@/utils/constant'
import { mapMutations } from 'vuex'
import {
  notificationDanger
} from '@/utils/notification'

export default {
  data() {
    return {
      password: '',
      confirmPassword: '',
      passwordFieldType: 'password',
      confirmPasswordFieldType: 'password',
      images: PREFIX_IMAGE,
      eyeIcon: 'eye.svg',
      eyeIconConfirm: 'eye.svg'
    }
  },
  methods: {
    ...mapMutations({
      setModalChange: 'user/SET_MODAL_CHANGE'
    }),
    goToBack() {
      window.UIkit.modal('#modal-out-confirm').show()
    },
    onSubmit() {
      if (this.password === this.confirmPassword) {
        this.$validator.validateAll().then((success) => {
          if (success) {
            if (this.$validator.errors.any()) return
            this.setModalChange({password: this.password})
            window.UIkit.modal('#modal-save-confirm').show()
          }
        })
      } else {
        notificationDanger({message: 'Password Harus Sama'})
      }
    },
    passwordVisibility() {
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password'
      this.eyeIcon = this.eyeIcon === 'eye.svg' ? 'eye-slash.svg' : 'eye.svg'
    },
    confirmPasswordVisibility() {
      this.confirmPasswordFieldType = this.confirmPasswordFieldType === 'password' ? 'text' : 'password'
      this.eyeIconConfirm = this.eyeIconConfirm === 'eye.svg' ? 'eye-slash.svg' : 'eye.svg'
    }
  }
}
</script>

<style scoped>
.profile-wrapper {
  border-radius: 10px;
  box-shadow: 0 2px 20px #00000014;
  padding: 30px;
}
.profile-content {
  display: flex;
  justify-content: space-between;
  margin: 16px 0;
}
h4 {
  width: 100%;
  font-family: ClanOT;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  color: #025231;
}
.input-wrapper {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.password-field {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
input {
  background: #ffffff;
  border-radius: 6px;
  color: #1F2E28;
}
.toggle-eye {
  position: absolute;
  margin-right: 10px;
}
.uk-button-primary {
  background-color: #1EC887;
  border-radius: 8px;
}
.uk-button-primary:hover {
  background-color: #024428;
}
</style>
